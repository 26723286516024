import React from "react";
import clsx from "clsx";

import photoRestaurant1 from "app/images/022223_bar_7527_with_screen.webp";
import photoRestaurant2 from "app/images/022223_casualdining_5399.webp";
import photoRestaurant3 from "app/images/022223_finedining_6531_with_screen.webp";
import photoRetail3 from "app/images/rectangle_139022.webp";
import photoRetail1 from "app/images/rectangle_139916.webp";
import photoRetail2 from "app/images/rectangle_139917.webp";

import styles from "./SuccessImagesStatic.module.scss";

export const SuccessImagesStatic = ({
    isRestaurant,
}: {
    isRestaurant: boolean;
}) => {
    const [photo1, photo2, photo3] = isRestaurant
        ? [photoRestaurant1, photoRestaurant2, photoRestaurant3]
        : [photoRetail1, photoRetail2, photoRetail3];
    return (
        <div className={styles.Wrapper}>
            <div className={clsx(styles.Images, "gap-3.5 tablet:gap-6")}>
                <img src={photo1} className={styles.TopImage} alt="photo1" />
                <div className="flex items-end gap-6">
                    <img
                        src={photo2}
                        className={clsx(styles.BottomImage)}
                        alt="photo2"
                    />
                    <img
                        src={photo3}
                        className={clsx(
                            styles.MidImage,
                            "mr-[21px] tablet:mr-[36px] mb-[33px] tablet:mb-[55px]",
                        )}
                        alt="photo3"
                    />
                </div>
            </div>
        </div>
    );
};
