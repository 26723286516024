import React from "react";
import { LoadingIndicator, Text } from "spoton-lib";

import { IPageComponentProps } from "app/types";
import { BusinessRebookActionType } from "features/store";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { SuccessImagesStatic } from "./SuccessImagesStatic";
import { WelcomeSetupRedirectBox } from "./WelcomeSetupRedirectBox.component";

import styles from "./SuccessPage.module.scss";

export const SuccessPage = ({ appStore }: IPageComponentProps) => {
    const { t } = useTranslation();
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                config: { businessRebookAction, isRestaurant, welcomeSetup },
            },
            loading: { isCompleteSignatureStepLoading },
        }) => ({
            isLoading: isCompleteSignatureStepLoading,
            businessRebookAction,
            isRestaurant,
            welcomeSetup,
        }),
    );
    const { isLoading, businessRebookAction, isRestaurant, welcomeSetup } =
        getState();
    const { isEnabled } = welcomeSetup;

    const resolveHeader = (businessRebookAction: BusinessRebookActionType) => {
        if (businessRebookAction) {
            return (
                <div className="flex flex-col gap-6">
                    <Text type="h1" className={styles.ContentWrapper_title}>
                        {t("successPageBusiness")}
                    </Text>
                    <Text type="sub2">
                        {t("successPageBusinessDescription")}
                    </Text>
                </div>
            );
        }

        return (
            <div className="flex flex-col gap-6">
                <Text className={styles.PageTitle} type="h3">
                    {t("successPagePageTitle")}
                </Text>
                <Text className={styles.PageSubTitle} type="sub1">
                    {t("successPageDescription")}
                </Text>
            </div>
        );
    };

    return (
        <div className={styles.ContentWrapper}>
            {isLoading ? (
                <div className={styles.LoadingContainer}>
                    <LoadingIndicator greyAnimation={true} />
                </div>
            ) : (
                <div className={styles.Page}>
                    <div className={styles.Content}>
                        {resolveHeader(businessRebookAction)}
                        {isEnabled && (
                            <WelcomeSetupRedirectBox
                                isRestaurant={!!isRestaurant}
                            />
                        )}
                    </div>
                    <SuccessImagesStatic isRestaurant={!!isRestaurant} />
                </div>
            )}
        </div>
    );
};
